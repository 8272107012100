import React from 'react';
import BlogCard from "../BlogCard/index"

import './styles.scss'

function BlogCards (props) {
    let posts = props.posts;
    return (
        <div className="columns BlogPage__columns is-multiline">
            {
                posts.map(post =>
                    <BlogCard post={post} />
                )
            }
        </div>
    )
}

export default BlogCards;
import React from "react"
import Layout from "../layout"
import parse from 'html-react-parser';
import { Link } from "gatsby"
import NewsletterSignupForm from '../../components/NewsletterSignupForm/index'
import BlogCards from '../../components/BlogCards/index'

import './styles.scss'

function HomeContent({ lang, location, posts }) {
  const dictionaryList = {
    en: {
      subheader: 'Digital product studio <br class="mobile-break"/>from Switzerland',
      header: 'We strategize, <br class="mobile-break"/>design <br class="non-mobile-break"/>& build <br class="mobile-break"/>digital experiences.',
      getInTouchCTA: 'Get in touch',
      introduction: {
        title: 'We are a digital product studio <br class="non-mobile-break"/>that transforms ideas into incredible products.',
        text: 'We partner with everyone, from tiny web startups to experienced enterprises, to help them build products that are simple, beautiful, and easy to use.'
      },
      whatWeDo: {
        subheader: 'How we can help you',
        header: 'One stop for digital product innovation',
        content: 'Every business challenge is unique. Our network of bright entrepreneurial minds, technical wizards, creatives, and project management experts allows us to create category-defining products. We develop simple solutions for complex problems.',
        discovery: {
          title: 'Discovery',
          content: 'We work together to understand your business objectives, aligning thoughts and ideas.'
        },
        productStrategy: {
          title: 'Product Strategy',
          content: 'Build products for tomorrow. We help you determine where to go and define the optimum way to get there.'
        },
        productDesign: {
          title: 'Product Design',
          content: 'Intuitive, seamless, and beautiful interfaces. <br />Always with a human-centered approach.'
        },
        engineering: {
          title: 'Engineering',
          content: 'We craft clean, secure, stable, and extensible code. The best technologies, frameworks and libraries are used to build progressive applications.'
        }
      },
      blog: {
        content: 'Gain a deeper understanding of innovative technologies, learn about best practices, and get the latest updates about Voa Labs.'
      },
      whoWeAre: {
        subheader: 'Who we are',
        header: 'About Voa Labs',
        content: [
          {
            title: 'We are a Swiss digital product studio committed to creating a positive impact',
            content: 'We strategize, design and build digital products, services, and experiences. We love technology and believe our work can have a positive impact on the world.<br /><br />Having the entire product development under one roof means a cohesive team to ensure forward momentum, guaranteeing a seamless delivery every time. From product strategy to design to full stack engineering, Voa Labs handles every aspect of the digital product development pipeline.'
          },
          {
            title: 'How we evolved',
            content: 'Voa Labs was founded by Joël Sonderegger who previously was a Product Manager Vice President at Sygnum, the world\'s first digital asset bank. During this time, he was responsible for building some of the bank’s core products. He draws upon more than 10 years of experience working with various organizations – from large international corporations to early stage startups. Joël started his career as an application developer apprentice at IBM. He holds a Master degree in business innovation from the University of St. Gallen (HSG).<br /><br />The name Voa Labs pays homage to Joël\'s favorite region on this planet - Graubünden, Switzerland.'
          }
        ]
      },
      contact: {
        subheader: 'Let\'s work together',
        header: 'Have a project or an idea you\'d like to collaborate on?',
        getInTouchCTA: 'Get in touch',
      },
      newsletter: {
        header: 'Sign up for our quarterly newsletter',
        subheader: 'Stay in the loop on the latest news and articles.'
      },
      footer: {
        emailCTA: 'email us at'
      }
    },
    de: {
      subheader: 'Digitales Produktstudio <br class="mobile-break"/>aus der Schweiz',
      header: 'Wir entwerfen <br class="mobile-break"/>digitale Produktideen, <br class="non-mobile-break"/>designen Interfaces und entwickeln <br class="non-mobile-break"/>digitale Produkte.',
      getInTouchCTA: 'Kontaktiere uns',
      introduction: {
        title: 'Wir sind ein digitales Produktstudio, <br class="non-mobile-break"/>das Ideen in unglaubliche Produkte verwandelt.',
        text: 'Wir arbeiten mit allen zusammen, von jungen Startups bis hin zu erfahrenen Unternehmen, um ihnen zu helfen, Produkte zu entwickeln, die simpel, innovativ und benutzerfreundlich sind.'
      },
      whatWeDo: {
        subheader: 'Was wir machen',
        header: 'Der Partner für digitale Produktinnovation',
        content: 'Wir sind ein interdisziplinäres Team bestehend aus unternehmerisch denkenden Strategen, technischen Spezialisten, kreativen Künstlern und Projektmanagement-Experten. Wir entwickeln innovative, leistungs­starke und sichere Produkte.',
        discovery: {
          title: 'Discovery',
          content: 'Wir arbeiten mit dir zusammen, um die Geschäftsziele deines Unternehmens zu verstehen und Ideen und Konzepte zu analysieren.'
        },
        productStrategy: {
          title: 'Produktstrategie',
          content: 'Wir helfen gezielt, bestehende Geschäftsmodelle aufzubrechen und zukunftsorientierte Produkte zu entwickeln.'
        },
        productDesign: {
          title: 'Produktdesign',
          content: 'Wir schaffen intuitive, einzigartige Benutzererlebnisse die begeistern. Dabei steht der Anwender stets im Mittelpunkt.'
        },
        engineering: {
          title: 'Engineering',
          content: 'Wir erstellen sicheren, stabilen und erweiterbaren Code. Projektspezifisch selektieren wir passende Technologien, Frameworks und Libraries.'
        }
      },
      whoWeAre: {
        subheader: 'Über uns',
        header: 'Digitales Produktstudio aus der Schweiz',
        content: [
          {
            title: 'Wir helfen unseren Kunden bei der Umsetzung von Ideen, <br class="non-mobile-break"/>von der ersten Skizze bis zur Markteinführung und darüber hinaus.',
            content: 'Wir entwickeln innovative digitale Produktstrategien, entwerfen Designs und implementieren Lösungen. Wir wollen etwas bewirken, sind Technologie-Enthusiasten und sind überzeugt, dass unsere Produkte das Leben von Anwendern einfacher und produktiver gestalten.<br /><br />Wir sind ein eingespieltes, interdisziplinäres Team, das Produktideen mit hoher Effizienz umsetzt. Von der Produktstrategie über das Produktdesign bis hin zum Full-Stack-Engineering übernimmt Voa Labs alle Aspekte der Produktentwicklung von digitalen Produkten.'
          },
          {
            title: 'Entstehung von Voa Labs',
            content: 'Voa Labs wurde von Joël Sonderegger gegründet, der ehemals als Product Manager Vice President bei Sygnum, der ersten Digital-Asset-Bank der Welt, tätig war. Während dieser Zeit war er für die Realisierung von Kernprodukten im Blockchain-Bereich verantwortlich. Joël Sonderegger verfügt über mehr als 10 Jahre Geschäftserfahrung und war während dieser Zeit für verschiedene Organisationen, von grossen internationalen Unternehmen bis hin zu Early-Stage-Startups, tätig. Seine Karriere begann er als Applikationsentwickler bei der IBM. Er verfügt über einen Master-Abschluss in Business Innovation der Universität St. Gallen (HSG).<br /><br />Der Name Voa Labs ist eine Hommage an Joël Sondereggers Lieblingsregion, dem Bündnerland in der Schweiz.'
          }
        ]
      },
      contact: {
        subheader: 'Lass uns zusammenarbeiten',
        header: 'Benötigst du Unterstützung bei einem Projekt oder hast du eine Produktidee?',
        getInTouchCTA: 'Kontaktiere uns',
      },
      newsletter: {
        header: 'Melde dich für unseren Newsletter an',
        subheader: 'Bleibe über die neuesten Entwicklungen bei Voa Labs auf dem Laufenden.'
      },
      footer: {
        emailCTA: ''
      }
    },
  };

  let content;
  lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

  const contactLink = `/${lang === 'de' ? 'de' : ''}/contact`;

  return (
    <div>
      <Layout lang={lang} location={location}>
        <section className="section" id="header">
          <div className="container">
            <div className="columns">
              <div className="column" id="header__left">
                <p className="whatweare">
                  {parse(content.subheader)}
                </p>
                <p className="whatwedo">
                  {parse(content.header)}
                </p>
                <Link to={contactLink} className="button">{parse(content.getInTouchCTA)}</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section content-section" id="intro-teaser">
          <div className="container">
            <h3 className="introduction__title">
              {parse(content.introduction.title)}
            </h3>
            <p className="introduction__text">
              {content.introduction.text}
            </p>
          </div>
        </section>
        <section className="section content-section" id="what-we-do">
          <div className="container">
            <div className="uptitle">{parse(content.whatWeDo.subheader)}</div>
            <div className="uptitle-line"></div>
            <h1>{parse(content.whatWeDo.header)}</h1>
            <p>
              {parse(content.whatWeDo.content)}
            </p>
            <div className="columns offering-list">



              <div className="column is-6">
                <img className="offering-icon" alt="Discovery Icon" src="/img/discovery-icon.png" />
                <h3>{parse(content.whatWeDo.discovery.title)}</h3>
                <p>
                  {parse(content.whatWeDo.discovery.content)}
                </p>
              </div>
              <div className="column is-6">
                <img className="offering-icon" alt="Product Strategy Icon" src="/img/product-strategy-icon.png" />
                <h3>{parse(content.whatWeDo.productStrategy.title)}</h3>
                <p>
                  {parse(content.whatWeDo.productStrategy.content)}
                </p>
              </div>
            </div>
            <div className="columns offering-list">
              <div className="column is-6">
                <img className="offering-icon" alt="Product Design Icon" src="/img/product-design-icon.png" />
                <h3>{parse(content.whatWeDo.productDesign.title)}</h3>
                <p>
                  {parse(content.whatWeDo.productDesign.content)}
                </p>
              </div>
              <div className="column is-6">
                <img className="offering-icon" alt="Engineering Icon" src="/img/engineering-icon.png" />
                <h3>{parse(content.whatWeDo.engineering.title)}</h3>
                <p>
                  {parse(content.whatWeDo.engineering.content)}
                </p>
              </div>
            </div>
          </div>

          {lang !== "de" &&
            <div align="center" className="Blog__linkToBlog">
              <br />

              <Link to="/services" className="link">Learn more</Link>
            </div>
          }
        </section>

        {lang !== "de" &&
          <section className="section content-section Blog__section">
            <div className="container">
              <h1>
                Blog
                </h1>
              <div className="columns">
                <div className="column is-8">
                  <p>
                    {parse(content.blog.content)}
                  </p>
                </div>
              </div>
              <div className="BlogCards__wrapper">
                <BlogCards posts={posts} nrOfPosts="3" />
              </div>
              <div align="center" className="Blog__linkToBlog">
                <Link to="/blog" className="link">View all articles</Link>
              </div>
            </div>
          </section>
        };

            <section className="section about-section content-section is-dark" id="who-we-are">
          <div className="container">
            <div className="uptitle">{parse(content.whoWeAre.subheader)}</div>
            <div className="uptitle-line"></div>
            <h1>{parse(content.whoWeAre.header)}</h1>
            <div className="columns">
              <div className="column text-vertical-aligned is-8">
                <div className="about-text-left">
                  <p className="About__intro">
                    {parse(content.whoWeAre.content[0].title)}
                  </p>
                  <p>
                    {parse(content.whoWeAre.content[0].content)}
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              <div className="column is-4">
                <img className="about-img"
                  data-sal="slide-up"
                  data-sal-delay="50"
                  data-sal-duration="800"
                  data-sal-easing="ease"
                  src="/img/work-image.png"
                  width="100%"
                  alt="Voa Labs Office"
                />
              </div>
            </div>
            <div className="columns reverse-columns">
              <div className="column is-4">
                <img className="about-img"
                  data-sal="slide-up"
                  data-sal-delay="50"
                  data-sal-duration="800"
                  data-sal-easing="ease"
                  src="/img/lenzerheide.png"
                  width="100%"
                  alt="Lenzerheide"
                />
              </div>

              <div className="column text-vertical-aligned is-8">
                <div className="about-text-right">
                  <h2>
                    {parse(content.whoWeAre.content[1].title)}
                  </h2>
                  <p>
                    {parse(content.whoWeAre.content[1].content)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="section content-section">
          <div className="container">
            <div className="uptitle">{parse(content.contact.subheader)}</div>
            <div className="uptitle-line"></div>
            <h1>{parse(content.contact.header)}</h1>
            <Link to={contactLink} className="button is-red">{parse(content.contact.getInTouchCTA)}</Link>
          </div>
        </section>
        <section className="section content-section Newsletter__section">
          <div className="container">
            <div className="columns Newsletter__sectionColumns">
              <div className={`column is-4 Newsletter__textWrapper`}>
                <p className="Newsletter__text">
                  {content.newsletter.header}
                </p>
                <p className="Newsletter__textSmall">
                  {content.newsletter.subheader}
                </p>
              </div>
              <div className="column is-5">
                <div className="Newsletter__formWrapper">
                  <NewsletterSignupForm button="outline" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default HomeContent;
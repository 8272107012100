import React from 'react';
import parse from 'html-react-parser';
import moment from 'moment';
import { Link } from "gatsby"

import './styles.scss'

function BlogCard(props) {
  return (
    <div className="column BlogCard__column is-one-third">
      <Link to={`/blog/${props.post.slug}`}>
        <div className="BlogCard">
          <div className="card-image BlogCard__image" style={{ backgroundImage: `url(${props.post.featureImageUrl})` }}>
          </div>
          <div className="BlogCard__content">
            <time datetime="2016-1-1" className="BlogCard__publishedDate">{moment(props.post.date).format('LL')}</time>
            <div className="content">
              <div className="BlogCard__title">{parse(props.post.title)}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BlogCard;
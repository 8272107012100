import React from "react"
import HomeContent from "../components/homeContent/index"
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

function Index({data, location}) {


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Voa Labs | Digital Product Studio</title>
        <meta name="description" content="Voa Labs entwirft digitale Produktideen, designed Interfaces und entwickelt digitale Produkte." />
      </Helmet>
    <HomeContent lang="de" location={location} />
    </div>
  );
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default Index;